import React from 'react';
import { Router } from '@reach/router';

import { Route } from '../components/router';
import { ContenidoEstaticoLayout } from '../layouts';

const ContenidoEstaticoPage = () => {
  return (
    <Router>
      <Route
        path="/contenidos/:staticContent/:subContent"
        component={ContenidoEstaticoLayout}
      />
    </Router>
  );
};

export default ContenidoEstaticoPage;
